import React, {useState, useEffect} from "react";
import {useParams} from "react-router-dom";

import {AnimatePresence, AnimateSharedLayout} from "framer-motion";
import {Button} from "react-bootstrap"
import {GridFill, List} from "react-bootstrap-icons";

import sectorCode from "../../data/json/sectorCode.json";
import {MainColor, Width} from "../../module/styled";

import Header from "../../module/common/header";
import MainContainer from "../../module/common/mainContainer";
import GridView from "./grid";
import ListView from "./list";
import Footer from "../../module/common/footer"
import CompanyDetail from "./companyDetail";

const Partner = () => {

    const {company_id} = useParams();
    const [view, setView] = useState('grid');

    const [totalCompanyList, setTotalCompanyList] = useState(null);
    const [companyList, setCompanyList] = useState(null);
    const [condition, setCondition] = useState(null);


    useEffect(() => {
        if (company_id !== undefined) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [company_id]);


    useEffect(() => {
        fetch('https://hmg-partners.com:4000/company/getShowCompanyList')
            .then(res => res.json())
            .then(json => {
                // console.log('JSON_RESULT', json.result);
                setTotalCompanyList(json.result);
            })

        var _condition = sectorCode.reduce((pre, cur) => {
            pre[cur.code] = false
            return pre
        },{})

        setCondition(_condition);
    },[]);


    useEffect(() => {
        if(totalCompanyList, condition){
            var _companyList;
            var _total = JSON.parse(JSON.stringify(totalCompanyList));

            if(!Object.keys(condition).find(key => condition[key])){
                _companyList = _total
            } else {
                _companyList = _total.filter(x => condition[x['industry_sectors']]);
            }

            setCompanyList(_companyList);
        }
    },[totalCompanyList, condition])


    return (
        <>
            <AnimatePresence>{companyList && company_id && <CompanyDetail id={company_id} companyList={companyList}/>}</AnimatePresence>
            <MainContainer pageName={'Partners'}>
                <Header style={{position: 'fixed', top: 0, left: 0, zIndex: 999}}/>
                <div style={{
                    width: Width, margin: '3vw 0', display: 'flex',
                    flexDirection: 'column', alignItems: 'flex-start',
                }}>
                    <span style={{fontSize: '1.3vw', fontWeight: 'bold'}}>The list of Partners</span>
                    {
                        companyList &&
                        <div style={{
                            width: '100%', display: 'flex',
                            flexDirection: 'column', alignItems: 'flex-start',
                        }}>
                            <div style={{
                                width: '100%', display: 'flex', flexWrap: 'wrap',
                                alignItems: 'center', justifyContent: 'space-between',
                                margin: '2vw 0'
                            }}>
                                {
                                    condition && [
                                        {code: '_total', show_text: 'Total',},
                                        ...sectorCode
                                    ].map((item, idx) => {
                                        return (
                                            <Button key={idx}
                                                    // variant={item.code === '_total' && !Object.keys(condition).find(key => condition[key]) ? 'primary' : condition[item.code] ? 'primary' : 'light'}
                                                    style={{
                                                        backgroundColor:item.code === '_total' && !Object.keys(condition).find(key => condition[key]) ? MainColor : condition[item.code] ? MainColor : '#eeeeee',
                                                        color:item.code === '_total' && !Object.keys(condition).find(key => condition[key]) ? 'white' : condition[item.code] ? 'white' : 'black',
                                                        width: '19%', padding: '2vh 0', display: 'flex',
                                                        alignItems: 'center', justifyContent: 'center',
                                                        marginTop: idx >= 5 && 10,
                                                        border:0,
                                                        // border: '1px solid #eeeeee'
                                                    }}
                                                    onClick={() => {
                                                        switch (item.code){
                                                            case '_total':
                                                                setCondition(prev => {
                                                                    var _condition = Object.keys(prev).reduce((pre, cur) => {
                                                                        pre[cur] = false;
                                                                        return pre;
                                                                    },{})

                                                                    return _condition
                                                                })
                                                                break;
                                                            default:
                                                                setCondition(prev => ({
                                                                    ...prev,
                                                                    [item.code] : !prev[item.code]
                                                                }))
                                                                break;
                                                        }
                                                    }}>
                                                <span style={{fontSize: '1.1vw', fontWeight: 'bold'}}>{item['show_text']}</span>
                                            </Button>
                                        )
                                    })
                                }
                            </div>

                            <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                                <div style={{
                                    width: '2.3vw', height: '2.3vw', display: 'flex',
                                    alignItems: 'center', justifyContent: 'center',
                                    borderRadius: '50%', backgroundColor: MainColor
                                }}>
                                    {
                                        view === 'list' ?
                                            <GridFill size={'1.2vw'} cursor={'pointer'} color={'white'}
                                                      onClick={() => setView('grid')}/>
                                            :
                                            <List size={'1.5vw'} cursor={'pointer'} color={'white'}
                                                  onClick={() => setView('list')}/>
                                    }
                                </div>
                            </div>

                            <div style={{width: '100%', display: 'flex', flexWrap:'wrap', justifyContent: 'center', marginTop: '1vw'}}>
                                {/*{*/}
                                {/*    view !== 'grid' &&*/}
                                {/*    <div style={{width:'100%', display:'flex', fontWeight:'800', fontStyle:'1.6vw', borderBottom:'1px soldi black'}}>*/}
                                {/*        <div style={{width:'20%'}}>*/}

                                {/*        </div>*/}
                                {/*        <div style={{width:'40%', paddingLeft:'5%'}}>*/}
                                {/*            <span>Company name</span>*/}
                                {/*        </div>*/}
                                {/*        <div style={{width:'40%', paddingLeft:'5%'}}>*/}
                                {/*            <span>Main Product</span>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*}*/}
                                {
                                    view === 'grid' ?
                                        <GridView companyList={companyList}/>
                                        :
                                        <ListView companyList={companyList}/>
                                }
                            </div>
                        </div>
                    }
                </div>

            </MainContainer>
        </>

    )
}

export default Partner
