import React from "react";
import {Routes, Route} from "react-router-dom";
import './App.css';

import Main from "./components/main";
import About from "./components/about";
import Partner from "./components/partner";
import Registration from "./components/registration";
import Contact from "./components/contact";
import Header from "./module/common/header";
import {MainColor} from "./module/styled";
import Footer from "./module/common/footer";


function App() {

    return (
        <div style={{width:'100%', height:'100%', position:'relative', overflow:'hidden'}}>
            <Routes>
                <Route path={'/'} element={<Main/>}/>
                <Route path={'/about'} element={<About/>}/>
                <Route path={'/partner'} element={<Partner/>}/>
                <Route path={'/partner/:company_id'} element={<Partner/>}/>
                <Route path={'/registration'} element={<Registration/>}/>
                <Route path={'/contact'} element={<Contact/>}/>
            </Routes>
            <div style={{width:'100%', backgroundColor:MainColor}}>
                <Footer style={{}}/>
            </div>
        </div>

    );
}

export default App;
