import React from "react";

import Header from "../../module/common/header";
import MainContainer from "../../module/common/mainContainer";
import {MainColor} from "../../module/styled";
import {Button} from "react-bootstrap";

const Contact = () => {

    return (
        <MainContainer pageName={'Contact'}>
            <Header style={{position: 'fixed', top: 0, left: 0, zIndex: 999}}/>

            <div style={{width:'90%', display:'flex', height:'60vh', margin:'5vh 0', alignItems:'center'}}>
                <div style={{width:'40%', display:'flex', justifyContent:'center'}}>
                    <div style={{width:'80%'}}>
                        <div style={{
                            // height:'35vh',
                            padding:'10px 0',
                            border:'1px solid '+MainColor,
                            fontSize:'1.1vw',
                            width:'100%',  display:'flex', flexWrap:'wrap', justifyContent:'center', alignItems:'center'}}>
                            <table style={{width:'90%'}}>
                                <tr>
                                    <td colSpan={2}>
                                        <div style={{padding:'10px 0'}}>
                                            <span style={{fontWeight:'700'}}>
                                             Korea Office
                                            </span>
                                        </div>
                                        <div style={{width:'10%', borderTop:'1px solid black', height:'5vh'}}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{width:'15%', height:'5vh', verticalAlign:'top'}}>
                                        <img src={require('../../images/contact_email.png')} width={25} height={25} alt=""/>
                                    </td>
                                    <td style={{width:'85%', paddingBottom:15}}>
                                        hmg.office@koreabridging.com
                                        <div style={{marginTop:5}}>
                                            <a href="mailto:hmg.office@koreabridging.com">
                                                <Button style={{border:0, backgroundColor:'#eeeeee', color:'black'}}>SEND EMAIL</Button>
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td  style={{verticalAlign:'top'}}>
                                        <img src={require('../../images/contact_location.png')} width={25} height={25} alt=""/>
                                    </td>
                                    <td style={{paddingBottom:20}}>
                                        4th floor, 10, Samseong-ro 104-gil, Gangnam-gu, Seoul, South Korea
                                    </td>
                                </tr>
                                <tr>
                                    <td  style={{verticalAlign:'top'}}>
                                        <img src={require('../../images/contact_phone.png')} width={25} height={25} alt=""/>
                                    </td>
                                    <td style={{width:'90%', paddingBottom:20}}>
                                        (+82) 2 555 8897
                                    </td>
                                </tr>
                            </table>
                        </div>
                        {/*<a target={'_blank'} href={'https://www.google.com/maps/place/(%EC%A3%BC)%EB%B8%8C%EB%A6%BF%EC%A7%95%EA%B7%B8%EB%A3%B9%EC%BD%94%EB%A6%AC%EC%95%84/data=!3m1!4b1!4m5!3m4!1s0x357ca46cad89669d:0x9f26ee120f51a56d!8m2!3d37.5107202!4d127.0554776'}>*/}
                        <div
                            onClick={()=>{
                                window.open('https://www.google.com/maps/place/(%EC%A3%BC)%EB%B8%8C%EB%A6%BF%EC%A7%95%EA%B7%B8%EB%A3%B9%EC%BD%94%EB%A6%AC%EC%95%84/data=!3m1!4b1!4m5!3m4!1s0x357ca46cad89669d:0x9f26ee120f51a56d!8m2!3d37.5107202!4d127.0554776')
                            }}
                            style={{width:'100%',
                                cursor:"pointer",
                            backgroundColor:MainColor, height:'7vh',
                            display:'flex', justifyContent:'space-between', alignItems:'center',
                            padding:'0px 30px', color:'white'
                        }}>
                            <span style={{color:'white'}}>Google Map</span>
                            <span style={{fontSize:'1.7vw'}}>→</span>
                        </div>
                        {/*</a>*/}
                    </div>
                </div>
                <div style={{width:'60%', height:'100%', display:'flex', justifyContent:'center'}}>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3164.8811929174676!2d127.05328891577!3d37.51072017980844!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca46cad89669d%3A0x9f26ee120f51a56d!2zKOyjvCnruIzrpr_sp5Xqt7jro7nsvZTrpqzslYQ!5e0!3m2!1sko!2skr!4v1672101393774!5m2!1sko!2skr"
                        width={'100%'}
                        height={'100%'}
                        style={{border:0}}
                        ></iframe>
                </div>
            </div>

        </MainContainer>
    )
}

export default Contact
