import React from "react";

import MainContainer from "../../module/common/mainContainer";
import Header from "../../module/common/header";
import Footer from "../../module/common/footer"
import {Width, MainColor} from "../../module/styled";
import {Fade, Zoom, Slide, LightSpeed} from 'react-reveal';





const About = () => {


    return (
        <MainContainer pageName={'About the Event'}>
            <Header style={{position: 'fixed', top: 0, left: 0, zIndex:'9999'}}/>

            <div style={{
                width: Width, display: 'flex', height:'100%',
                flexDirection: 'column', alignItems: 'flex-start',
                fontSize: '1.4vw', whiteSpace:'pre-wrap',
                marginBottom:'10vh'
            }}>
                <div style={{width:'100%', display:'flex', justifyContent:'center', backgroundColor:'#eeeeee', margin:'5vh 0px'}}>
                    <div style={{
                        width:'70%', textAlign:'center',
                        padding:'5vh 0'}}>
                    <span style={{fontSize:'1.3vw', fontWeight:'700'}}>
                        HYUNDAI MOTOR GROUP PARTNERS VIRTUAL MEETING EVENT provides valuable network and opportunity not only for global buyers who need to diversify their supply chain or switch existing sourcing partners but also to begin a new project for launching new products or receiving new orders.
                    </span>
                    </div>
                </div>



                <div style={{
                    width: '100%', marginBottom: '15vh',
                    display: 'flex', fontSize: '1.4vw',
                }}>
                    <Fade left ssrFadeout>
                    <div style={{width: '50%'}}>
                        <img src={require('../../images/about_1.jpg')} width={'100%'} height={'100%'} alt=""/>
                    </div>
                    </Fade>
                    <Fade right ssrFadeout>
                    <div style={{
                        display: 'flex', flexWrap: 'wrap', alignItems: 'center',
                        width: '50%', padding: '0px 3vw',
                        // textAlign:'center'
                    }}>
                        <span>
                            <span style={{fontWeight: '700'}}>Save your time and energy</span><br/><br/>
                            Our online data provides organized information to find out appropriate business partners for you whenever it is, and wherever you are.
                        </span>
                    </div>
                    </Fade>
                </div>

                <div style={{
                    width: '100%', marginBottom: '15vh',
                    display: 'flex', fontSize: '1.4vw',
                }}>
                    <Fade left ssrFadeout>
                    <div style={{
                        display: 'flex', flexWrap: 'wrap', alignItems: 'center',
                        width: '65%', padding: '0px 3vw',
                        // textAlign:'center'
                    }}>
                        <span>
                            <span style={{fontWeight: '700'}}>Get the timely response</span><br/><br/>
                            Simple contact to a strange company is not easy even if you’re not in the same country. By reaching out to a interesting company via our event, we promise you can get the timely answer for your inquiry.
                        </span>
                    </div>
                    </Fade>
                    <Fade right ssrFadeout>
                        <div style={{width: '50%'}}>
                            <img src={require('../../images/about_2.jpg')} width={'100%'} height={'100%'} alt=""/>
                        </div>
                    </Fade>
                </div>

                <div style={{
                    width: '100%', marginBottom: '15vh',
                    display: 'flex', fontSize: '1.4vw',
                }}>
                    <Fade left ssrFadeout>
                    <div style={{width: '50%'}}>
                        <img src={require('../../images/about_3.jpg')} width={'100%'} height={'100%'} alt=""/>
                    </div>
                    </Fade>
                    <Fade right ssrFadeout>
                    <div style={{
                        display: 'flex', flexWrap: 'wrap', alignItems: 'center',
                        width: '50%', padding: '0px 3vw',
                        // textAlign:'center'
                    }}>
                        <span>
                            <span style={{fontWeight: '700'}}>Certified by Hyundai Motor Group</span><br/><br/>
                            All the companies registered here as a partner of Hyundai Motor Group are now actively cooperating with the group. Their products and technologies are certified by Hyundai Motor Group, one of the top global automobile brands.
                        </span>
                    </div>
                    </Fade>
                </div>


            </div>

        </MainContainer>
    )
}

export default About
