import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";

import {motion} from "framer-motion";
import {Download} from "react-bootstrap-icons";

import {MainColor} from "../../module/styled";


const CompanyDetail = ({id, companyList}) => {

    const navigate = useNavigate();
    const [company,setCompany] = useState(null);

    useEffect(() => {
        var _company = companyList.find(x => x['company_id'] === parseInt(id));
        setCompany(_company);
    },[id])


    if(company)
        return (
            <motion.div initial={{opacity: 0}} animate={{opacity: 1}}
                        exit={{opacity: 0, transition: {duration: 0.15}}}
                        style={{
                            position: 'fixed', top: 0, left: 0, zIndex: 1000,
                            width: '100%', height: '100%', display: 'flex',
                            alignItems: 'center', justifyContent: 'center',
                            backgroundColor: '#00000080'
                        }}>
                <div style={{
                    backgroundColor:'white',
                    borderRadius:15,
                    height:'90%',
                    paddingBottom:'5vh',
                    width: '45vw', display: 'flex',
                    flexDirection: 'column', alignItems: 'center'
                }}>
                    <div style={{
                        height:'7vh',
                        // backgroundColor:'red',
                        width: '100%', marginBottom: 3,
                        display: 'flex',justifyContent: 'flex-end',
                        borderBottom:'1px solid lightgray'
                    }}
                         onClick={() => navigate('/partner')}>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', paddingRight:20}}>
                            <img src={require('../../images/close.png')} width={20} height={20} alt=""/>
                        </div>

                        {/*<span style={{fontSize: '1.1vw', fontWeight: 'bold', color: 'black', cursor: 'pointer'}}>Close X</span>*/}
                    </div>

                    <motion.div layoutId={`${company['company_id']}`}
                                className={'no_sb'}
                                style={{
                                    width: '100%', height: '86vh', overflowY: 'scroll',
                                    padding: '1.5vw 2vw', display: 'flex',
                                    flexDirection: 'column', alignItems: 'center',
                                }}>
                        <div style={{width: '94%', display: 'flex', flexDirection: 'column'}}>
                            <motion.div layoutId={`image_${id}`}
                                        style={{
                                            border:0,
                                            width: '100%',
                                            // height: '19.62vw',
                                            height:'13vw',
                                            display: 'flex',
                                            alignItems: 'center', justifyContent: 'center',
                                            overflow: 'hidden',
                                            borderBottom:'1px solid lightgray'
                                            // boxShadow: '0 0 3px 3px #eeeeee'
                                        }}>
                                <img alt={id}
                                     // style={{width: '103%'}}
                                    // style={{border:'1px solid white'}}
                                    //  height={'103%'}
                                     width={'103%'}
                                    // style={{margin:-40}}
                                     src={`https://hmg-partners.com:4000/imgs/${company['company_logo_img_path']}`}/>
                            </motion.div>

                            <div style={{
                                width: '100%', display: 'flex',
                                flexDirection: 'column', alignItems: 'flex-start',
                            }}>
                                <div style={{
                                    padding:'4vh 0',
                                    // backgroundColor: '#EBF0F8',
                                    width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <span style={{fontSize: '1.3vw', fontWeight: '900', color: MainColor}}>{company['company_name']}</span>

                                    {
                                        company['brochure_file_path'] &&
                                        <div style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}
                                             onClick={() => {
                                                 window.open(`https://hmg-partners.com:4000/zipFile/${company['brochure_file_path']}`)
                                             }}>
                                            <div style={{
                                                width: '1.8vw', height: '1.8vw', display: 'flex',
                                                alignItems: 'center', justifyContent: 'center',
                                                backgroundColor: MainColor, borderRadius: '50%',
                                            }}>
                                                <Download  size={'0.9vw'} color={'white'}/>
                                            </div>
                                            <span style={{marginLeft: 5, fontSize: '1vw', fontWeight: 'bold', color:MainColor}}>BROCHURE</span>
                                        </div>
                                    }
                                </div>

                                <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                                    {
                                        [
                                            {key: 'industry_sectors', show_key: 'Industry/Sectors',},
                                            {key: 'capitals', show_key: 'Capitals',},
                                            {key: 'headcounts', show_key: 'Headcounts',},
                                            {key: 'turnover', show_key: 'Turnover(2021)',},
                                            {key: 'founded_in', show_key: 'Founded in',},
                                            {key: 'address', show_key: 'Company Address',},
                                            {key: 'company_size', show_key: 'Company Size',},
                                            {key: 'major_clients', show_key: 'Major Clients',},
                                            {key: 'website', show_key: 'Website',},
                                            {key: 'main_products', show_key: 'Main Products',},
                                            {key: 'affiliates', show_key: 'Affiliates'}
                                        ].map((item, idx) => {
                                            if(company[item.key])
                                                return (
                                                    // <div key={idx}
                                                    //      style={{
                                                    //          width: '100%', marginTop: idx && '3vh',
                                                    //          display: 'flex', flexDirection: 'column',
                                                    //          alignItems: 'flex-start',
                                                    //      }}>
                                                    //     <span style={{fontSize: '1vw', fontWeight: 600, color: MainColor}}>{item['show_key']}</span>
                                                    //     <span style={{marginTop: 3, fontSize: '1.1vw', fontWeight: 'bold', color: 'gray', lineHeight: 1.1}}>{company[item.key]}</span>
                                                    // </div>
                                                    <div key={idx}
                                                         style={{
                                                             width: '100%',
                                                             // marginTop: idx && '1.3vh',
                                                             display:'flex',
                                                             fontSize: '1.1vw', fontWeight: 600,
                                                             borderTop:'1px solid #f5f5f5',
                                                             padding:'1.2vh'
                                                         }}>
                                                        <div style={{width:'35%'}}>
                                                            <span style={{color: MainColor, fontWeight:'700'}}>{item['show_key']}</span>
                                                        </div>
                                                        <div style={{width:'65%'}}>
                                                            <span style={{color:'#626D87'}}>{company[item.key]}</span>
                                                        </div>
                                                    </div>
                                                )
                                        })
                                    }
                                </div>

                            </div>
                        </div>

                    </motion.div>
                </div>
            </motion.div>
        )
}

export default CompanyDetail
