import React, {useEffect, useState, useRef} from "react";
import {useNavigate} from "react-router-dom";
import {TransitionGroup, CSSTransition} from "react-transition-group";

import menuList from "../../data/json/menu.json";
import {Width, MainColor, BrightColor} from "../../module/styled";
import Header from "../../module/common/header"
import Footer from "../../module/common/footer";
import {Fade, Zoom, Slide, LightSpeed} from 'react-reveal';

import Hmg_logo from '../../images/hmg_logo.svg';
const imgList = [
    {
        key: 'example01',
        src : require('../../images/main_bg_01.jpg')
    },
    {
        key: 'example02',
        src : require('../../images/main_bg_02.jpg')
    },
    {
        key: 'example03',
        src : require('../../images/main_bg_03.jpg')
    },
]


const Main = () => {

    const hoverRef = useRef([]);
    const navigate = useNavigate();

    const [delay, setDelay] = useState(5000)
    const [count, setCount] = useState(0);


    useEffect(() => {
        const interval = setInterval(() => {
            setCount(prevState => {
                return (prevState + 1) % imgList.length
            })
        }, delay);

        return () => clearInterval(interval);
    },[]);

    // useEffect(()=>{
    //     var el = document.getElementById('bg');
    //     el.style.backgroundImage = "url("+imgList[count]+")"
    // },[count])



    return (
        <div style={{width:'100%', height:'100%', overflowX:"hidden"}}>
            <Header style={{position: 'absolute', top: 0, left: 0, zIndex: 999}}/>
            {/*<TransitionGroup>*/}
            {/*    <CSSTransition classNames={'slide'}*/}
            {/*                   key={imgList[count]['key']}*/}
            {/*                   timeout={{enter: delay, exit: delay}}>*/}
            {/*        <img src={imgList[count]['src']} alt={'bg_slider'}*/}
            {/*             style={{*/}
            {/*                 // objectFit:"fill",*/}
            {/*                 position: 'absolute', top: 0, left: 0,*/}
            {/*                 width: '100%', height: '100vh',*/}
            {/*             }}/>*/}
            {/*    </CSSTransition>*/}
            {/*</TransitionGroup>*/}
            <div
                // id={'bg'}
                className={'opacity_bg'}
                style={{
                backgroundImage:"url("+imgList[count]['src']+")",
                backgroundSize:"cover",
                position:'absolute', top:0, left:0, width:'100%', height:'100%'
            }}/>
            <div style={{
                // position: 'absolute', top: 0, left: 0,
                width: '100%', height:'100vh', display: 'flex', flexWrap:'wrap',
                alignItems: 'center', justifyContent: 'center',
                // backgroundColor: '#00000090'
                // backgroundColor:'white'
            }}>

                <div style={{
                    width: '80%', height: '70%', display: 'flex',
                    alignItems: 'center', justifyContent: 'space-between',
                }}>
                    <div style={{
                        width: '40%', height: '100%',
                    }}>
                        <div style={{
                            width: '100%',
                            height:'100%',
                            display: 'flex',

                            // justifyContent:'center',
                            flexWrap:'wrap',
                            justifyContent:'flex-start',
                            alignItems: 'center',
                            // backgroundColor:'white'
                        }}>

                            <Fade left>
                                <div style={{width:'65%', display:'flex', justifyContent:'center', flexWrap:'wrap', textAlign:'center'}}>
                                    <div style={{}}>
                                        <img height={70} src={Hmg_logo} alt=""/>
                                    </div>
                                    <div style={{width:'100%', marginTop:15, marginBottom:15}}>
                                    <span style={{whiteSpace: 'pre-wrap', fontSize: 16, color: '#fdbe2b'}}>
                                        {/*{'Make your business more stable and profitable.\nTake new chance with certified partners from Korea'}*/}
                                        CLICK WHAT YOU’RE<br/>LOOKINGFOR
                                    </span>
                                    </div>
                                </div>
                            </Fade>

                            <div style={{
                                width:'100%', height:'80%', display:'flex', flexWrap:'wrap', justifyContent:'space-between',
                            }}>
                            {
                                menuList.map((item, idx) => {
                                    return (
                                        <Fade left>
                                            <div

                                                className={'mainBtn'}
                                                key={idx}
                                                 // ref={(el) => hoverRef.current[item.key] = el}
                                                 onClick={() => navigate(item.path)}
                                                 // onMouseEnter={() => {
                                                 //     hoverRef.current[item.key].style.border = `2px solid ${BrightColor}`;
                                                 //     hoverRef.current[item.key].style.background = MainColor;
                                                 //     hoverRef.current[item.key].style.color = 'white';
                                                 // }}
                                                 // onMouseLeave={() => {
                                                 //     hoverRef.current[item.key].style.border = '2px solid #ffffff80';
                                                 //     hoverRef.current[item.key].style.background = 'none';
                                                 //     hoverRef.current[item.key].style.color = '#ffffff80';
                                                 // }}
                                                 style={{
                                                     // marginLeft:idx % 2 == 1 ? '15%' : 0,
                                                     height:'20%',
                                                     width: '65%', padding: '0.8vw 0',
                                                     display: 'flex',
                                                     alignItems: 'center', justifyContent: 'center',
                                                     cursor: 'pointer',
                                                     border: '3px solid lightgray',
                                                     // color: '#ffffff'
                                                     // backgroundColor:'white'
                                                     color:'lightgray',
                                                     backgroundColor:'rgba(56,55,55,.35)',
                                                 }}>
                                                <span style={{fontSize: '1.5vw', fontWeight: 'bold'}}>{item['show_key'].toUpperCase()}</span>
                                            </div>
                                        </Fade>
                                    )
                                })
                            }
                            </div>

                        </div>
                    </div>
                    <Zoom>
                    <div style={{
                        width:'60%', height: "fit-content",
                        padding:'4%',
                        border: '2px solid white', borderRadius: '0.5rem',
                        color:'white',
                        backgroundColor:'rgba(24, 45, 74,0.55)',
                            // 'rgba(0, 75, 141, 0.4)',
                        // backgroundColor:'rgba(255,255,255,.2)',
                        textAlign:'center',
                    }}>
                        <div style={{marginBottom:15}}>
                            <span style={{color:'#92D050'}}>
                                It's Fully opened!
                            </span>
                        </div>
                        <div style={{}}>
                            <span style={{fontSize: '1.7vw', fontWeight: 'bold', textAlign:'center'}}>
                                HYUNDAI MOTOR GROUP PARTNERS<br/>VIRTUAL MEETING EVENT
                            </span>
                        </div>
                        <div style={{marginTop:10, marginBottom:10}}>
                            <span style={{fontSize: '1.6vw', color: '#fdbe2b', fontWeight: 'bold',}}>
                                DEC 2022 - MAR 2023
                            </span>
                        </div>
                            <span style={{whiteSpace:'pre-wrap', fontSize:'1.2vw'}}>
                                {'\n'}Welcome to Hyundai Motor Group Partners Virtual Meeting Event.{'\n'}This event provides you to a chance for understanding better about automotive parts industries and diversifying your supply chain.{'\n'}{'\n'}Not only just checking the suppliers’ information but also making an arrangement to discuss possible business cooperation will be yours.{'\n'}Make your business stronger with Korean automotive parts suppliers!
                            </span>


                        {/*</div>*/}

                    </div>
                    </Zoom>
                </div>

            </div>
        </div>
    )
}

export default Main
