import React from "react";
import {useNavigate} from "react-router-dom";
import {motion} from "framer-motion";
import {LazyLoadImage} from "react-lazy-load-image-component";

import {BrightColor, MainColor} from "../../module/styled";

const ListView = ({companyList}) => {
    console.log(companyList)
    const navigate = useNavigate();
    const header = [
        {key: 'company_logo_img_path', show_key: '', width: '20%',},
        {key: 'company_name', show_key: '', width: '40%',},
        {key: 'main_products', show_key: '', width: '40%',},
    ];

    return (
        <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            {
                companyList.map((company, cIdx) => {
                    return (
                        <motion.div key={company['company_id']}
                                    layoutId={`${company['company_id']}`}
                                    whileHover={{scale: 1.1}}
                                    onClick={() => navigate(`/partner/${company['company_id']}`)}
                                    style={{
                                        width: '100%', margin: '0.5vw 0', display: 'flex',
                                        alignItems: 'center', justifyContent: 'center',
                                        // backgroundColor : cIdx % 2 && `${BrightColor}20`
                                        backgroundColor:'#eeeeee',
                                        // border:'1px solid '+MainColor,
                                        border:'1px solid lightgray',
                                        color:'black'
                                        // borderRadius : 8,
                                    }}>
                            <div style={{width: '100%', display: 'flex', alignItems: 'center',}}>
                                {
                                    header.map((head, hIdx) => {
                                        return (
                                            <div key={hIdx}
                                                 style={{
                                                     width: head.width, height: '100%', display: 'flex',
                                                     alignItems: 'center',
                                                     // justifyContent: 'center',
                                                 }}>
                                                {
                                                    (() => {
                                                        switch (head.key){
                                                            case 'company_logo_img_path':
                                                                return (
                                                                    <motion.div layoutId={`image_${company['company_id']}`}
                                                                                style={{
                                                                                    backgroundColor:'white',
                                                                                    width: '100%',
                                                                                    height: '6.7vw',
                                                                                    display: 'flex',
                                                                                    alignItems: 'center', justifyContent: 'center',
                                                                                    overflow: 'hidden',
                                                                                }}>
                                                                        {/*<LazyLoadImage alt={company['company_id']} width={'103%'}*/}
                                                                        {/*               src={'https://hmg-partners.com:4000/imgs/' + company[head.key]}/>*/}
                                                                        <img
                                                                            src={'https://hmg-partners.com:4000/imgs/' + company[head.key]}
                                                                            alt={company['company_id']} width={'103%'}
                                                                        />
                                                                    </motion.div>
                                                                )
                                                            default:
                                                                return (
                                                                    <div  style={{padding:'0% 5%'}}>
                                                                    <span className={'line-clamp2'} style={{
                                                                        color:'black',
                                                                        fontSize: '1.2vw', fontWeight: 'bold'}}>{company[head.key]}</span>
                                                                    </div>
                                                                )
                                                        }
                                                    })()
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </motion.div>
                    )
                })
            }
        </div>
    )
}

export default ListView
