import React from "react";
import {useNavigate} from "react-router-dom";
import {motion} from "framer-motion";
import {LazyLoadImage} from "react-lazy-load-image-component";

import {BrightColor, MainColor} from "../../module/styled";


const GridView = ({companyList}) => {

    const navigate = useNavigate();

    return (
        <div style={{width: '100%', display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start',}}>
            {
                companyList.map((company, cIdx) => {
                    return (
                        <motion.div key={company['company_id']}
                                    layoutId={`${company['company_id']}`}
                                    whileHover={{scale: 1.1}}
                                    onClick={() => navigate(`/partner/${company['company_id']}`)}
                                    style={{
                                        width: '18.8%',
                                        // padding: '0.5vw 0',
                                        display: 'flex', flexDirection: 'column',
                                        marginTop: cIdx >= 5 && '1vw', marginLeft: cIdx % 5 && '1.5%',
                                        alignItems: 'center',
                                        // borderBottomLeftRadius: 8,
                                        // borderBottomRightRadius: 8,
                                        // backgroundColor: `${BrightColor}40`
                                        backgroundColor:'#eeeeee',
                                        border:'1px solid lightgray',
                                        // padding:-1,
                                        color:'black'
                                    }}>
                            <motion.div layoutId={`image_${company['company_id']}`}
                                        aria-hidden={true}
                                        style={{
                                            width: '100%', height: '5.96vw', display: 'flex',
                                            alignItems: 'center', justifyContent: 'center',
                                            overflow: 'hidden',
                                        }}>
                                {/*<LazyLoadImage alt={company['company_id']} width={'103%'}*/}
                                {/*               src={'https://hmg-partners.com:4000/imgs/' + company['company_logo_img_path']}/>*/}
                                <img salt={company['company_id']} width={'103%'}
                                     src={'https://hmg-partners.com:4000/imgs/' + company['company_logo_img_path']}/>
                            </motion.div>

                            <div style={{
                                width: '90%', marginTop: '0.5vw', display: 'flex',
                                flexDirection: 'column', alignItems: 'flex-start',
                                padding:'10px 0'
                            }}>
                                 <span style={{
                                     whiteSpace: 'nowrap',
                                     width: '100%', overflow: 'hidden', textOverflow: 'ellipsis',
                                     fontWeight: 'bold', fontSize: '0.8vw', lineHeight: 1
                                 }}>{company['company_name']}</span>
                                {/*<span style={{marginTop: 6, fontSize: '1.vw', lineHeight: 1, color: 'gray'}}>{company['hashtag']}</span>*/}
                            </div>

                            {/*<motion.img alt={cIdx} style={{width: '90%'}}*/}
                            {/*            variants={animation} transition={transition}*/}
                            {/*            initial={'hidden'} animate={'show'}*/}
                            {/*            src={'https://hmg-partners.com:4000/imgs/' + company['company_logo_img_path']}/>*/}


                        </motion.div>
                    )
                })
            }
        </div>
    )
}

export default GridView
