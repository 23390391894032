import React, {useState, useRef} from "react";
import {useLocation, useNavigate} from "react-router-dom";

import menuList from "../../data/json/menu.json"
import {BrightColor, MainColor, Width} from "../styled";

const Header = ({style}) => {

    const hoverRef = useRef([]);
    const location = useLocation();
    const navigate = useNavigate();

    const [color, setColor] = useState(false);

    const changeColor = () => {
        if(window.scrollY > 80){
            setColor(true)
        } else {
            setColor(false)
        }
    }
    window.addEventListener('scroll', changeColor);
    if(location.pathname !== '/'){
        return (
            <div style={{
                width: '100vw', height: '4.5vw', display: 'flex',
                alignItems: 'center', justifyContent: 'center',
                borderBottom: '1px solid white', backgroundColor: color && MainColor,
                ...style
            }}>
                <div style={{width: Width, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <span onClick={() => navigate('/')}
                      style={{
                          cursor: 'pointer', whiteSpace: 'pre-wrap', lineHeight: 1.1,
                          fontSize: '1vw', fontWeight: 'bold', color: 'white',
                      }}>
                    {'HYUNDAI MOTOR GROUP PARTNERS VIRTUAL MEETING EVENT\nDECEMBER 2022 - MARCH 2023'}
                </span>

                    {/*{*/}
                    {/*    location.pathname !== '/' &&*/}
                    <div style={{
                        display: 'flex', alignItems: 'center', justifyContent: 'center',
                    }}>
                        {
                            menuList.map((item, idx) => {
                                return (
                                    <div key={idx}
                                         ref={(el) => hoverRef.current[item.key] = el}
                                         onMouseEnter={() => {
                                             hoverRef.current[item.key].style.color = BrightColor;
                                         }}
                                         onMouseLeave={() => {
                                             hoverRef.current[item.key].style.color = 'white';
                                         }}
                                         style={{display: 'flex', alignItems: 'center', color: 'white'}}>
                                        {
                                            idx > 0 &&
                                            <span style={{margin: '0 1vw', fontWeight: 'bold', color: 'white'}}>|</span>
                                        }
                                        <span onClick={() => navigate(item.path)}
                                              style={{fontSize: '1.2vw', fontWeight: 'bold', cursor: 'pointer',}}>{item.show_key}</span>
                                    </div>
                                )
                            })
                        }
                    </div>
                    {/*}*/}
                </div>
            </div>
        )
    }else{
        return(
            <div style={{
                width: '100%', height: '4.5vw',
                textAlign:'right',
                paddingRight:'3%',
                paddingLeft:'3%',
                position:'absolute', top:0,
                backgroundColor:MainColor,
                display:'flex', alignItems:'center', justifyContent:'flex-end',
                fontSize:'1.9vh'

            }}>

                 <span style={{color:'white', whiteSpace:'pre-wrap', fontWeight:'bold'}}>
                       HYUNDAI MOTOR GROUP PARTNERS VIRTUAL MEETING EVENT{'\n'}
                        DECEMBER 2022 - MARCH 2023
                   </span>
            </div>
        )
    }

}

export default Header
