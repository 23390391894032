import React, {useState, useEffect} from "react";
import {CheckSquareFill, Square} from "react-bootstrap-icons";
import moment from "moment";

import {Accordion, Form, Button} from "react-bootstrap";

import TimeRangePicker from "@wojtekmaj/react-timerange-picker";
import {DayPicker} from "react-day-picker";
import "react-day-picker/dist/style.css";

import registrationInfo from "../../data/json/registrationInfo.json";
import countryCode from "../../data/json/countryCode.json";
import sectorCode from "../../data/json/sectorCode.json";

import {Width, MainColor, BrightColor} from "../../module/styled";
import Header from "../../module/common/header";
import MainContainer from "../../module/common/mainContainer";
import Footer from "../../module/common/footer";
import {useNavigate} from "react-router-dom";


const Registration = () => {

    const [info, setInfo] = useState(null);
    const [selectedDate, setSelectedDate] = useState([]);
    const [dateInfo, setDateInfo] = useState([]);

    const navigate=useNavigate();


    useEffect(() => {
        var _info = registrationInfo.reduce((pre, cur) => {
            pre[cur.key] = cur.key === 'preferred_sector' ? [] : null;
            return pre
        }, {});

        setInfo(_info);
    },[]);


    useEffect(() => {
        let _sortedDate = selectedDate.sort((a, b) => a - b);
        let _dateInfo = _sortedDate.reduce((pre, cur) => {
            if(cur){
                var _fIdx = dateInfo.findIndex(x => moment(x['date']).format('YYYYMMDD') === moment(cur).format('YYYYMMDD'));
                // pre.push({
                //     date: cur,
                //     time: _fIdx >= 0 ? dateInfo[_fIdx]['time'] : null
                // });
                pre.push(moment(cur).format('YYYY-MM-DD'));
                return pre
            }
        },[]);

        setDateInfo(_dateInfo);
    },[selectedDate]);


    const checkBeforeRegistration = (_info, _dateInfo) => {
        if(_dateInfo.length === 0){
            // console.log(_)
            return false
        }
        // else {
        //     if(_dateInfo.findIndex(x => x['time'] === null) >= 0){
        //         return false
        //     }
        // }

        for (let {key, required} of registrationInfo) {
            if(required){
                if(_info[key] === null){
                    return false
                } else {
                    if(_info[key].length <= 0) {
                        return false
                    }
                }
            }
        }

        return true;
    }


    const onSubmit = (_info, _dateInfo) => {

        var _regInfo = {..._info,
            preferred_sector: _info['preferred_sector'].join(','),
            available_date: _dateInfo.join(',')
        }

        fetch('https://hmg-partners.com:4000/inquiry/registerInquiry', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                data : _regInfo
            }),
        })
            .then(res => res.json())
            .then(json => {
                console.log(json)
                if(json.suc){
                    navigate('/')
                }else{
                    alert('Save Failed. Please try again')
                }
            });
    }


    return (
        <MainContainer pageName={'Registration'}>
            <Header style={{position: 'fixed', top: 0, left: 0, zIndex: 999}}/>
            <div style={{
                width: Width, margin: '3vw 0', display: 'flex',
                flexDirection: 'column', alignItems: 'flex-start',
            }}>
                <span style={{fontSize: '1.3vw', fontWeight: 'bold'}}>To register, please fill in the form below.</span>
                {
                    info &&
                        <div style={{
                            width: '100%', display: 'flex', marginTop: '2vw',
                            flexDirection: 'column', alignItems: 'flex-start',
                        }}>
                            {
                                [
                                    {key: 'company', show_key: 'Company information',},
                                    {key: 'participant', show_key: 'Participant’s information',},
                                    {key: 'demand', show_key: 'Your demand & interest',},
                                    {key: 'date_time', show_key: 'Your available date & time',},
                                ].map((item, idx) => {
                                    return (
                                        <div key={idx}
                                             style={{
                                                 width: '100%', display: 'flex', marginTop: idx && '3vw',
                                                 flexDirection: 'column', alignItems: 'flex-start'
                                             }}>
                                            <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                                <span style={{fontSize: '1.5vw', fontWeight: 'bold'}}>{item['show_key']}</span>
                                                {item.key === 'date_time' && <span style={{color: BrightColor, fontSize: '0.8vw', fontWeight: 'bold'}}>*required</span>}
                                            </div>

                                            <div style={{
                                                width: '100%', display: 'flex', marginTop: '1vw',
                                                flexDirection: 'column', alignItems: 'flex-start'
                                            }}>
                                                {
                                                    item.key === 'date_time' ?
                                                        <div style={{
                                                            width: '100%', display: 'flex',
                                                            alignItems: 'flex-start', justifyContent: 'space-between'
                                                        }}>
                                                            <DayPicker
                                                                numberOfMonths={2}
                                                                mode={"multiple"}
                                                                min={1}
                                                                selected={selectedDate}
                                                                onSelect={setSelectedDate}
                                                                footer={<span/>}
                                                            />

                                                            <div style={{
                                                                backgroundColor: dateInfo.length > 0 ? '#f5f5f5' : 'white',
                                                                padding:'5px 10px',
                                                                width: '35%', marginTop: '1vw', flexWrap:'wrap', display:'flex', border:
                                                                    dateInfo.length > 0 ? '1px solid lightgray' : 0,
                                                            }}>
                                                                {
                                                                    dateInfo?.sort((a, b) => a - b).map((item, idx) => {
                                                                        return (
                                                                                <div style={{
                                                                                    width:'45%',
                                                                                    padding:5,
                                                                                    display: 'flex',
                                                                                    alignItems: 'flex-end', justifyContent: 'flex-start'
                                                                                }}>
                                                                                    <span style={{fontSize: '1.2vw', fontWeight: 'bold'}}>{moment(item['date']).format('YYYY-MM-DD')}{idx < dateInfo.length - 1 && ','}</span>
                                                                                </div>
                                                                            // <Accordion.Item key={idx}
                                                                            //                 eventKey={idx}>
                                                                            //     <Accordion.Header>
                                                                            //         <div style={{
                                                                            //             width: '100%', display: 'flex',
                                                                            //             alignItems: 'flex-end', justifyContent: 'flex-start'
                                                                            //         }}>
                                                                            //             <span style={{fontSize: '1vw', fontWeight: 'bold'}}>{moment(item['date']).format('YYYY-MM-DD')}</span>
                                                                            //             {
                                                                            //                 item['time'] &&
                                                                            //                     <span style={{marginLeft: 5, fontSize: '0.85vw', color: 'gray'}}>
                                                                            //                         {`(${item['time'][0]} ~ ${item['time'][1]})`}
                                                                            //                     </span>
                                                                            //             }
                                                                            //         </div>
                                                                            //     </Accordion.Header>
                                                                            //     <Accordion.Body>
                                                                            //         <TimeRangePicker className={'time_range_picker'}
                                                                            //                          value={item['time'] || ['00:00', '00:00']}
                                                                            //                          clearIcon={null} clockIcon={null} disableClock={true}
                                                                            //                          format={"HH:mm a"}
                                                                            //                          onChange={(v) => {
                                                                            //                              var _dateInfo = JSON.parse(JSON.stringify(dateInfo));
                                                                            //
                                                                            //                              _dateInfo[idx]['time'] = v
                                                                            //                              setDateInfo(_dateInfo);
                                                                            //                          }}/>
                                                                            //     </Accordion.Body>
                                                                            // </Accordion.Item>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                        :
                                                        registrationInfo.filter(x => x['division'] === item.key).map((head,hIdx) => {
                                                            return (
                                                                <div key={hIdx}
                                                                     style={{
                                                                         width: '100%', marginTop: hIdx && '1.5vw', display: 'flex',
                                                                         flexDirection: 'column', alignItems: 'flex-start'
                                                                     }}>
                                                                    <Form style={{width: '100%'}}>
                                                                        {
                                                                            (() => {
                                                                                switch (head.key){
                                                                                    case 'company_information':
                                                                                    case 'purpose':
                                                                                    case 'preferred_detail':
                                                                                        return (
                                                                                            <Form.Group>
                                                                                                <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                                                                                    <Form.Label>{head['show_key']}</Form.Label>
                                                                                                    {head.required && <span style={{color: BrightColor, fontSize: '0.8vw', fontWeight: 'bold'}}>*required</span>}
                                                                                                </div>
                                                                                                <Form.Control as={'textarea'} rows={3}
                                                                                                              onChange={(e) => {
                                                                                                                  setInfo(prevState => ({
                                                                                                                      ...prevState,
                                                                                                                      [head.key] : e.target.value
                                                                                                                  }))
                                                                                                              }}/>
                                                                                            </Form.Group>
                                                                                        )
                                                                                    case 'country':
                                                                                        return (
                                                                                            <Form.Group>
                                                                                                <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                                                                                    <Form.Label>{head['show_key']}</Form.Label>
                                                                                                    {head.required && <span style={{color: BrightColor, fontSize: '0.9vw', fontWeight: 'bold'}}>*required</span>}
                                                                                                </div>

                                                                                                <Form.Select size={'sm'} value={info[head.key] || ''}
                                                                                                             onChange={(e) => {
                                                                                                                 setInfo(prevState => ({
                                                                                                                     ...prevState,
                                                                                                                     [head.key] : e.target.value
                                                                                                                 }))
                                                                                                             }}>
                                                                                                    <option value={null}/>
                                                                                                    {
                                                                                                        countryCode.map((country, cIdx) => {
                                                                                                            return (
                                                                                                                <option key={cIdx} value={country['code']}>
                                                                                                                    {country['show_text']}
                                                                                                                </option>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </Form.Select>
                                                                                            </Form.Group>
                                                                                        )
                                                                                    case 'preferred_sector':
                                                                                        return (
                                                                                            <Form.Group>
                                                                                                <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                                                                                    <Form.Label>{head['show_key']}</Form.Label>
                                                                                                    {head.required && <span style={{color: BrightColor, fontSize: '0.8vw', fontWeight: 'bold'}}>*required</span>}
                                                                                                </div>
                                                                                                <div style={{
                                                                                                    width: '100%', display: 'flex', flexWrap: 'wrap',
                                                                                                    alignItems: 'center', justifyContent: 'space-between',
                                                                                                }}>
                                                                                                    {
                                                                                                        sectorCode.map((sector, sIdx) => {
                                                                                                            return (
                                                                                                                <Form.Check key={sIdx} type={'checkbox'}
                                                                                                                            checked={info[head.key].includes(sector['code'])}
                                                                                                                            label={sector['show_text']} style={{width: '33%',}}
                                                                                                                            onChange={() => {
                                                                                                                                var _sector = [...info[head.key]];
                                                                                                                                var _fIdx = _sector.findIndex(x => x === sector['code']);

                                                                                                                                if(_fIdx >= 0){
                                                                                                                                    _sector.splice(_fIdx, 1);
                                                                                                                                } else {
                                                                                                                                    _sector.push(sector['code'])
                                                                                                                                }

                                                                                                                                setInfo(prevState => ({
                                                                                                                                    ...prevState,
                                                                                                                                    [head.key] : _sector
                                                                                                                                }))
                                                                                                                            }}
                                                                                                                />
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </div>
                                                                                            </Form.Group>
                                                                                        )
                                                                                    default:
                                                                                        return (
                                                                                            <Form.Group>
                                                                                                <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                                                                                    <Form.Label>{head['show_key']}</Form.Label>
                                                                                                    {head.required && <span style={{color: BrightColor, fontSize: '0.8vw', fontWeight: 'bold'}}>*required</span>}
                                                                                                </div>
                                                                                                <Form.Control onChange={(e) => {
                                                                                                    setInfo(prevState => ({
                                                                                                        ...prevState,
                                                                                                        [head.key] : e.target.value
                                                                                                    }))
                                                                                                }}/>
                                                                                            </Form.Group>
                                                                                        )
                                                                                }
                                                                            })()
                                                                        }
                                                                    </Form>
                                                                </div>
                                                            )
                                                        })
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }

                            <div style={{width: '100%', marginTop: 30, display: 'flex', justifyContent: 'center'}}>
                                <Button style={{width: '26%', fontWeight: 'bold', backgroundColor: MainColor}}
                                        onClick={() => {
                                            // var _chk = checkBeforeRegistration(info, dateInfo);
                                            // if(_chk){
                                            //     onSubmit(info, dateInfo);
                                            // } else {
                                            //     alert('CHECK REQUIRED')
                                            // }
                                            onSubmit(info, dateInfo);
                                }}>Submit</Button>
                            </div>
                        </div>
                }
            </div>

        </MainContainer>
    )
}

export default Registration
