import React, {useState, useEffect} from "react";
import {Width, MainColor} from "../styled";

const MainContainer = ({style, pageName, children}) => {

    const delay = 200;
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        },delay)
    },[]);


    return (
        <div style={{
            position: 'relative',
            overflowX:'hidden',
            width: '100vw', height: '100%', display: 'flex',
            flexDirection: 'column', alignItems: 'center',
            backgroundColor: 'white', opacity: loading ? 0 : 1,
            transition: `opacity ${delay}ms`, ...style,
             }}>
            <div style={{
                position: 'relative',
                width: '100%', paddingTop: '4.5vw', display: 'flex',
                justifyContent: 'center',
            }}>
                <div style={{
                    position: 'absolute', top: 0, left: 0,
                    width: '100%', height: '100%', display: 'flex',
                    alignItems: 'center', justifyContent: 'center',
                    overflow: 'hidden',
                }}>
                    <img alt={'main_bg'} src={require('../../images/main_bg.jpg')} style={{width: '100%'}}/>
                </div>
                <div style={{
                    position: 'absolute', top: 0, left: 0,
                    width: '100%', height: '100%', backgroundColor: '#00000090'
                }}/>

                <div style={{
                    position: 'relative',
                    width: Width, padding: '3.5vw 0', display: 'flex',
                    justifyContent: 'flex-start', alignItems: 'center',
                }}>
                    <span style={{fontSize: '2.5vw', fontWeight: 'bold', color: 'white'}}>{pageName}</span>
                </div>
            </div>

            {children}
        </div>
    )
}

export default MainContainer
