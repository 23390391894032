import React from "react";
import {Width} from "../styled";

const Footer = ({style}) => {

    return (
        <div style={{
            width: '100vw', height: '25vh', display: 'flex',
            alignItems: 'center', justifyContent: 'center',
            // borderTop: '1px solid white',
            ...style
        }}>
            <div style={{width: Width, display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
                // height:'10vh'
            }}>
                <div style={{width:'20%'}}>
                    <img src={require('../../images/hmg_logo.png')} width={'60%'} alt=""/>
                    <div style={{marginTop:20}}>
                        <img src={require('../../images/kofca_logo.png')} width={'60%'} alt=""/>
                    </div>
                </div>x
                <div style={{width:'80%', color:'lightgray'}}>
                    <div style={{display:'flex', flexWrap:'wrap', width:'100%'}}>
                        <div style={{marginBottom:10}}>
                            <span style={{fontWeight:'900'}}>BRIDGING PARTNERS Co., Ltd.</span>
                        </div>
                    </div>
                    <div>
                        <span>4th floor, 10, Samseong-ro 104-gil, Gangnam-gu, Seoul, South Korea</span>
                    </div>
                    <div style={{display:'flex', flexWrap:'wrap', width:'100%'}}>
                        <div style={{paddingRight:'3%', marginRight:'3%'}}>
                            <span>Tel. 02-555-8897</span>
                        </div>
                        <div>
                            <span>Eamil. hmg.office@koreabridging.com</span>
                        </div>
                    </div>
                    <div style={{width:'100%', marginTop:10}}>
                        <span style={{color:'gray'}}>© Bridging Partners. All rights reserved.</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
